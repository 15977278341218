<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة الطالب </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>

          <v-text-field v-model="studentName" outlined label="اسم الطالب" :rules="studentRequiredFormRules"></v-text-field>

          <v-text-field v-model="phone" outlined label=" رقم الهاتف" :rules="studentRequiredFormRules" type="number"></v-text-field>

          <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="date" outlined label="تاريخ الميلاد" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="date" @input="menu2 = false"></v-date-picker>
          </v-menu>

          <v-autocomplete v-model="gender" outlined label="الجنس" item-text="text" item-value="value" :rules="studentRequiredFormRules" :items="genders" item-color="accent"></v-autocomplete>

          <v-text-field v-model="address" outlined label="العنوان" :rules="studentRequiredFormRules"></v-text-field>

          <v-text-field v-model="certificate" outlined label=" الشهادة" :rules="studentRequiredFormRules"></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="saveData" v-on:keyup.enter="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة الطالب</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data: () => ({
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    show: false,
    phone: "",
    studentName: "",
    menu2: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, 10),
    genders: [
      { text: "ذكر", value: "MALE" },
      { text: "انثى", value: "FEMALE" },
    ],
    gender: "",
    address: "",
    certificate: "",
  }),

  methods: {
    saveData() {
      let self = this;

      if (self.studentName && self.phone && self.date && self.gender && self.address && self.certificate) {
        let student = {
          studentName: self.studentName,
          phone: self.phone,
          dob: self.date,
          gender: self.gender,
          address: self.address,
          certificate: self.certificate,
        };

        console.log(student);

        this.$http
          .post(
            `${this.$store.state.apiUrlFees}/addStudentInformation`,

            student,
            {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }
          )
          .then((res) => {
            this.show = false;
            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
          })
          .catch((err) => {
            this.$toasted.error(" حدث خطأ في البيانات");
          });
      } else {
        this.$toasted.error("الرجاء ادخال ادخال جميع القيم");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
