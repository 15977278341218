<template>
  <div>
    <v-container class="container not-printable" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="10">
            <h3>الكورسات</h3>
          </v-col>
          <v-col cols="2" align="left">
            <AddCourse style="display: inline-block" @reloadTask="initialization" />
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="container not-printable" fluid>
      <v-card elevation="6">
        <v-card-title>
          <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="courses" :items-per-page="15" :search="search" item-key="idStudent" class="table-content">
          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>
          <template v-slot:[`item.coursePrice`]="{ item }">
            <p style="padding: 0 3px">
              {{
                item.coursePrice.toLocaleString("en-US", {
                  style: "currency",
                  currency: "IQD",
                })
              }}
            </p>
          </template>
          <!-- <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="dialog = false" v-if="$store.state.userData.idEmployee != 614">
              <v-icon @click="deleteItem(item)" color="primary"> mdi-delete </v-icon>
            </v-btn>
          </template> -->
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import AddCourse from "@/components/course/AddCourse.vue";
export default {
  components: {
    AddCourse,
  },
  data() {
    return {
      courses: [],
      search: "",
      headers: [
        { text: "التسلسل", value: "index" },
        { text: "اسم الكورس", value: "courseName" },
        { text: "سعر الكورس", value: "coursePrice" },
        { text: "الاجراءات", value: "actions" },
      ],
    };
  },
  created() {
    this.initialization();
  },
  methods: {
    initialization() {
      let self = this;
      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrlFees}/courses`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((courses) => {
            self.courses = courses.data;
          })
        )
        .catch((e) => console.log(e));
    },
  },
};
</script>

<style lang="scss" scoped></style>
