<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة كورس </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>

          <v-autocomplete
            v-model="course"
            outlined
            label="الكورس"
            item-text="courseName"
            item-value="idCourse"
            :rules="studentRequiredFormRules"
            :items="allCourses"
            item-color="accent"
          ></v-autocomplete>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="saveData" v-on:keyup.enter="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" icon small color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة كورس</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    allCourses: Array,
    studentId: Number,
  },
  data: () => ({
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    show: false,
    coursePrice: "",
    course: "",
    courseName: "",
    label: " المبلغ",
    placeholder: " ",
    readonly: false,
    disabled: false,
    outlined: true,
    clearable: true,
    valueWhenIsEmpty: "",
    options: {
      locale: "pt-BR",
      prefix: "IQ",
      suffix: "",
      length: 11,
      precision: 0,
    },
  }),

  methods: {
    saveData() {
      let self = this;

      if (self.course) {
        let course = {
          courseId: self.course,
          studentInformationId: self.studentId,
        };

        this.$http
          .post(
            `${this.$store.state.apiUrlFees}/addStudentCourse`,

            course,
            {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }
          )
          .then((res) => {
            this.show = false;
            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
          })
          .catch((err) => {
            this.$toasted.error(" حدث خطأ في البيانات");
          });
      } else {
        this.$toasted.error("الرجاء ادخال ادخال جميع القيم");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
