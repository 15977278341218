<template>
  <div>
    <v-container class="container not-printable" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="10">
            <h3>القبض اليومي</h3>
          </v-col>
          <v-col cols="2" align="left">
            <v-btn icon @click="receivedMoney">
              <v-icon color="primary" size="30">mdi-cash-sync</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="container not-printable" fluid>
      <v-row>
        <v-col cols="3" v-for="(pay, index) in payment" :key="index">
          <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
            <v-row>
              <v-col cols="4">
                <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
              </v-col>
              <v-col cols="8" class="division-title">
                <h4 class="warning-color" style="font-size: 1em; font-weight: bold">{{ pay.employeeName }}</h4>
              </v-col>
            </v-row>
            <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
              {{
                (pay.total * 1).toLocaleString("en-US", {
                  style: "currency",
                  currency: "IQD",
                })
              }}
            </h4>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      payment: [],
    };
  },
  created() {
    this.initialization();
  },
  methods: {
    initialization() {
      let self = this;
      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrlFees}/coursePaysToday`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((payment) => {
            self.payment = payment.data;
          })
        )
        .catch((e) => console.log(e));
    },
    setDate(isoDate) {
      const date = new Date(isoDate);
      // const formattedDate = date.toISOString().slice(0, 19).replace("T", " ");
      const formattedDate = date.toLocaleDateString().split("/");

      return `${formattedDate[2]}-${formattedDate[0] < 10 ? "0" + formattedDate[0] : formattedDate[0]}-${formattedDate[1] < 10 ? "0" + formattedDate[1] : formattedDate[1]}`;
    },
    receivedMoney() {
      let self = this;
      let pay = self.payment.reduce((n, { total }) => n + total, 0);
      let student = {
        receiptDate: self.setDate(self.payment[0].createdAt),
        notes: "ايراد التعليم المستمر",
        amount: pay,
        studyYearId: 75,
        createdBy: 413,
        isInternal: 2,
        receiptTypeId: 12,
        boxId: 22,
        employeeId: 413,
        date: self.setDate(self.payment[0].createdAt),
      };

      console.log(student);

      this.$http
        .put(`${this.$store.state.apiUrlFees}/coursePayDate`, student, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          this.show = false;
          this.$toasted.success("تم اضافه البيانات");
          self.initialization();
        })
        .catch((err) => {
          this.$toasted.error(" حدث خطأ في البيانات");
        });
      console.log(pay * 1);
    },
  },
};
</script>

<style lang="scss" scoped></style>
