<template>
  <div>
    <v-container class="container not-printable" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="10">
            <h3>الطلبة</h3>
          </v-col>
          <v-col cols="2" align="left">
            <AddStudent style="display: inline-block" @reloadTask="initialization" v-if="!ShowDetails" />
            <v-btn icon @click="ShowDetails = false" v-if="ShowDetails">
              <v-icon color="error"> mdi-arrow-left </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="container not-printable" fluid v-if="!ShowDetails">
      <v-card elevation="6">
        <v-card-title>
          <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="students" :items-per-page="15" :search="search" item-key="idStudent" class="table-content">
          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <!-- <v-btn icon @click="dialog = false" v-if="$store.state.userData.idEmployee != 614">
              <v-icon @click="deleteItem(item)" color="primary"> mdi-delete </v-icon>
            </v-btn> -->
            <v-btn icon @click="dialog = false" v-if="$store.state.userData.idEmployee != 614">
              <v-icon @click="goToDetails(item)" color="primary"> mdi-card-account-details </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <v-container class="container not-printable" fluid v-if="ShowDetails">
      <v-card elevation="6"> <StudentInformationDetails :courses="courses" :student="selectedStudent" /> </v-card>
    </v-container>
  </div>
</template>

<script>
import AddStudent from "@/components/course/student/AddStudent.vue";
import StudentInformationDetails from "@/components/course/student/StudentInformationDetails.vue";
export default {
  components: {
    AddStudent,
    StudentInformationDetails,
  },
  data() {
    return {
      ShowDetails: false,
      search: "",
      selectedStudent: null,
      courses: [],
      students: [],
      headers: [
        { text: "التسلسل", value: "index" },
        { text: "اسم الطالب", value: "studentName" },
        { text: "رقم الهاتف", value: "phone" },
        { text: "تاريخ الميلاد", value: "dob" },
        { text: "الجنس", value: "gender" },
        { text: "العنوان", value: "address" },
        { text: "الشهادة", value: "certificate" },
        { text: "الاجراءات", value: "actions" },
      ],
    };
  },
  created() {
    this.initialization();
  },
  methods: {
    initialization() {
      let self = this;

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrlFees}/studentsInformation`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlFees}/courses`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((students, courses) => {
            self.students = students.data;
            self.courses = courses.data;
          })
        )
        .catch((e) => console.log(e));
    },
    goToDetails(student) {
      let self = this;
      //   studentIdCourses

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrlFees}/studentIdCourses/${student.idStudentInformation}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlFees}/studentCoursePay/${student.idStudentInformation}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((course, pays) => {
            self.ShowDetails = true;
            console.log(pays.data);
            self.$store.state.selectedStudent = { ...student, courses: course.data, payments: pays.data };
            self.selectedStudent = student;
          })
        )
        .catch((e) => console.log(e));
    },
  },
};
</script>

<style lang="scss" scoped></style>
