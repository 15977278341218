import { render, staticRenderFns } from "./CoursePay.vue?vue&type=template&id=2dde1c21&scoped=true&"
import script from "./CoursePay.vue?vue&type=script&lang=js&"
export * from "./CoursePay.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dde1c21",
  null
  
)

export default component.exports