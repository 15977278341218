<template>
  <div>
    <div class="notPrintable">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-container class="container sticky-header" fluid>
        <v-card elevation="6">
          <v-row style="margin: 10px">
            <v-col cols="3">
              <h3>{{ $store.state.studentDetails.studentName }}</h3>
            </v-col>
            <v-col cols="3" align="center">
              <h3>قناة القبول : {{ $store.state.studentDetails.acceptedName }}</h3>
            </v-col>

            <v-col cols="3" align="center">
              <h3 style="padding-top: 3px">
                القسم :
                {{ $store.state.studentDetails.sectionName }}
              </h3>
            </v-col>

            <v-col cols="2" align="center">
              <h3 v-if="$store.state.studentDetails.studentStatusId == 3">متخرج</h3>
              <h3 v-else>المرحلة : {{ $store.state.studentDetails.level | setLevel }}</h3>
            </v-col>

            <v-col cols="1" align="left">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mx-2" fab small color="primary" @click="$router.go(-1)" v-bind="attrs" v-on="on">
                    <v-icon dark> mdi-arrow-left </v-icon>
                  </v-btn>
                </template>
                <span>رجوع</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
      <canvas id="barcode"></canvas>
      <v-container class="container" fluid v-if="$store.state.studentDetails?.level && $store.state.studentDetails.studentStatusId != 3">
        <v-alert v-if="!isPaidIdentity() && $store.state.studentDetails?.level != 1" dense outlined text type="warning" border="left"> هوية الطالب الالكترونية غير مدفوعة </v-alert>
        <div style="margin-top: 10px"></div>
        <v-alert v-if="!isPaidService() && $store.state.studentDetails?.level != 1" dense outlined text type="warning" border="left"> الخدمات الجامعية غير مدفوعة </v-alert>
        <v-card>
          <v-row>
            <v-col cols="3">
              <div style="padding: 10px">
                <h5 style="padding-top: 3px">
                  نوع الدراسة :
                  {{ $store.state.studentDetails.studyType == 2 ? "مسائي" : "صباحي" }}
                </h5>

                <h5 style="padding-top: 15px">
                  المبلغ الكلي :
                  {{
                    sectionInstallmentStudent.toLocaleString("en-US", {
                      style: "currency",
                      currency: "IQD",
                    })
                  }}
                </h5>
              </div>
            </v-col>
            <v-col cols="3">
              <div style="padding: 10px">
                <h5 style="padding-top: 15px">الرقم الجامعي : {{ $store.state.studentDetails.collegeNumber }}</h5>
                <h5 style="padding-top: 15px">
                  المبلغ بعد التخفيض :
                  {{
                    getStudentLevelDiscountAmount($store.state.studentDetails.level, $store.state.studentDetails.yearStudyId) != undefined
                      ? (sectionCost - getStudentLevelDiscountAmount($store.state.studentDetails.level, $store.state.studentDetails.yearStudyId).amount * 1).toLocaleString("en-US", {
                          style: "currency",
                          currency: "IQD",
                        })
                      : sectionCost.toLocaleString("en-US", {
                          style: "currency",
                          currency: "IQD",
                        })
                  }}
                </h5>
              </div>
            </v-col>
            <v-col cols="3">
              <h5 style="padding-top: 15px">
                نسبة التسديد :
                {{
                  isNaN(parseInt((currentStudentPay / sectionCost) * 100))
                    ? 0
                    : parseInt(
                        ((currentStudentPay +
                          getStudentCircularAmount() +
                          (getStudentLevelDiscountAmount($store.state.studentDetails.level, $store.state.studentDetails.yearStudyId)?.amount
                            ? getStudentLevelDiscountAmount($store.state.studentDetails.level, $store.state.studentDetails.yearStudyId)?.amount
                            : 0)) /
                          sectionCost) *
                          100
                      )
                }}%
              </h5>
              <h5 style="padding-top: 15px">
                المسدد الفعلي :
                {{
                  currentStudentPay.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h5>
            </v-col>

            <v-col cols="2">
              <h5 style="padding-top: 15px">نسبة التخفيض : {{ $store.state.studentDetails.discount }} ٪</h5>
              <h5 style="padding-top: 15px">
                المتبقي :
                {{
                  getStudentLevelDiscountAmount($store.state.studentDetails.level, $store.state.studentDetails.yearStudyId) != undefined
                    ? (
                        sectionCost -
                        currentStudentPay -
                        getStudentCircularAmount() -
                        getStudentLevelDiscountAmount($store.state.studentDetails.level, $store.state.studentDetails.yearStudyId).amount
                      ).toLocaleString("en-US", {
                        style: "currency",
                        currency: "IQD",
                      })
                    : (sectionCost - currentStudentPay - getStudentCircularAmount()).toLocaleString("en-US", {
                        style: "currency",
                        currency: "IQD",
                      })
                }}
              </h5>
            </v-col>
            <v-col cols="1">
              <h5 style="padding-top: 15px">
                حالة الطالب :
                {{ $store.state.studentDetails.statusName }}
              </h5>
              <span v-if="profilePhotos.length > 0">
                <ShowStudentProfilePhotos style="display: inline-block; padding-top: 15px" :profilePhotos="profilePhotos" />
              </span>
            </v-col>
          </v-row>
        </v-card>
      </v-container>

      <v-container class="container" fluid v-if="!$store.state.studentDetails?.level">
        <v-card>
          <v-row>
            <v-col cols="3">
              <div style="padding: 10px">
                <h5 style="padding-top: 3px">
                  القسم :
                  {{ $store.state.studentDetails.sectionName }}
                </h5>
              </div>
            </v-col>
            <v-col cols="3">
              <div style="padding: 10px">
                <h5 style="padding-top: 3px">
                  نوع الدراسة :
                  {{ $store.state.studentDetails.studyType == 2 ? "مسائي" : "صباحي" }}
                </h5>
              </div>
            </v-col>
            <v-col cols="3">
              <h5 style="padding-top: 15px">الرقم الجامعي : {{ $store.state.studentDetails.collegeNumber }}</h5>
            </v-col>

            <v-col cols="3">
              <h5 style="padding-top: 15px">قيمة التخفيض : {{ $store.state.studentDetails.discount }} ٪</h5>
            </v-col>
          </v-row>
        </v-card>
      </v-container>

      <div style="margin: 10px 0"></div>
      <v-container class="container" fluid>
        <v-card>
          <v-row v-if="$store.state.userData.idEmployee != 614">
            <v-col cols="4"
              ><AddStudentPay
                :paymentTypesAll="paymentTypesAll"
                :studentPay="$store.state.subFeeStudentDetails"
                :sectionInstallment="sectionCost"
                :remaining="sectionCost - currentStudentPay"
                @reloadTask="initialData()"
                style="padding: 10px; display: inline-block"
                v-if="isStudentTransfer()"
              />
              <v-alert v-else dense outlined text type="error" border="left">الطالب غير مرحل</v-alert>
            </v-col>

            <v-col cols="4" style="text-align: center">
              <AddStudentProfilePhoto style="padding: 10px; display: inline-block" @reloadTask="initialData()" :collegeNumber="$store.state.studentDetails.collegeNumber" />
            </v-col>

            <v-col cols="4" style="text-align: left">
              <div v-if="$store.state.userData.roleId != 61">
                <EditAcceptedType style="padding: 10px; display: inline-block" @reloadTask="initialData()" :acceptedTypes="acceptedTypes" />
              </div>
            </v-col>
          </v-row>

          <v-card
            v-for="(level, index) in allStudentFees"
            :key="index"
            style="margin: 20px 0"
            :color="
              getTheStudentPreviousData(level[0].studentLevel, level[0].studyYearId, level) > 0 && level[0].studyYearId > 72 && $store.state.studentDetails.studentStatusId != 3 ? '#ff6b6b' : '#f7f7f7'
            "
          >
            <div>
              <v-row v-if="$store.state.studentDetails.studentStatusId == 3">
                <v-col cols="6">
                  <!-- <h5 style="padding: 20px; text-align: right">متخرج</h5> -->
                  <h5 style="padding: 20px; text-align: right">{{ level[0].studentLevel | setLevel }}</h5>
                </v-col>
                <v-col cols="6">
                  <h5 style="padding: 20px; text-align: left">{{ level[0].studentLevel != 10 ? level[0].year : "" }}</h5>
                </v-col>
              </v-row>

              <v-row v-else>
                <v-col cols="3">
                  <h5 style="padding: 20px; text-align: right">{{ level[0].studentLevel | setLevel }}</h5>
                </v-col>
                <v-col cols="3">
                  <span v-if="studentDiscountAmount.length == 0 || getStudentLevelDiscountAmount(level[0].studentLevel, level[0].studyYearId) == undefined" style="text-align: center">
                    <div v-if="$store.state.userData.roleId != 61">
                      <AddStudentDiscountAmount
                        v-if="$store.state.userData.idEmployee != 614"
                        :collegeNumber="level[0].collegeNumber"
                        :yearStudyId="level[0].studyYearId"
                        :studentLevel="level[0].studentLevel"
                        @reloadTask="initialData()"
                      />
                    </div>
                  </span>

                  <h5 v-else style="padding: 20px; text-align: center">
                    <v-btn icon color="pink" @click="deleteDiscountAmount(getStudentLevelDiscountAmount(level[0].studentLevel, level[0].studyYearId))">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    قيمة التخفيض :
                    {{
                      getStudentLevelDiscountAmount(level[0].studentLevel, level[0].studyYearId).amount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "IQD",
                      })
                    }}
                    <EditDiscountAmount
                      :collegeNumber="level[0].collegeNumber"
                      :yearStudyId="level[0].studyYearId"
                      :studentLevel="level[0].studentLevel"
                      :discount="getStudentLevelDiscountAmount(level[0].studentLevel, level[0].studyYearId)"
                      @reloadTask="initialData()"
                      v-if="$store.state.userData.roleId != 61"
                      style="display: inline-block"
                    />

                    <ShowDiscountAmountNotes
                      style="display: inline-block"
                      :notes="getStudentLevelDiscountAmount(level[0].studentLevel, level[0].studyYearId).notes"
                      :imagePath="getStudentLevelDiscountAmount(level[0].studentLevel, level[0].studyYearId).imagePath"
                      v-if="$store.state.userData.idEmployee != 614"
                    />
                  </h5>
                </v-col>
                <v-col cols="3">
                  <h5 style="padding: 20px; text-align: left">
                    قناة القبول :
                    {{ findChannelForLevel(level[0].studentLevel).acceptedName }}
                  </h5>
                </v-col>
                <v-col cols="3">
                  <h5 style="padding: 20px; text-align: left">{{ level[0].year }}</h5>
                </v-col>
              </v-row>
            </div>

            <v-data-table :headers="headers" :items="level" :items-per-page="15" item-key="idStudent" class="table-content">
              <template v-slot:[`item.index`]="{ index }">
                <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
              </template>

              <template v-slot:[`item.studentLevel`]="{ item }">
                <p style="padding: 0 3px">{{ item.studentLevel | setLevel }}</p>
              </template>

              <template v-slot:[`item.sectionVoucher`]="{ item }">
                <p style="padding: 0 3px">{{ $store.state.sections.find((section) => section.idSection == item.voucherSectionId).sectionName }}</p>
              </template>

              <template v-slot:[`item.amount`]="{ item }">
                <h4 style="padding: 0 3px">
                  {{
                    item.amount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "IQD",
                    })
                  }}
                </h4>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn icon @click="printVoucher(item, level)">
                  <v-icon color="primary"> mdi-printer </v-icon>
                </v-btn>
                <div v-if="$store.state.userData.roleId != 61" style="display: inline-block">
                  <EditSectionIdVoucher :studentPay="item" v-if="$store.state.userData.idEmployee != 614" />
                </div>
                <SoftDeleteStudentVoucher style="display: inline-block" :item="item" @reloadTask="initialData" v-if="$store.state.userData.roleId != 61 && $store.state.userData.idEmployee != 614" />
              </template>
            </v-data-table>

            <v-row v-if="level[0].studentLevel != 10">
              <v-col cols="4">
                <h5 style="padding: 10px">
                  المجموع :
                  {{
                    totalInstallmentPayed(level).toLocaleString("en-US", {
                      style: "currency",
                      currency: "IQD",
                    })
                  }}
                </h5>
              </v-col>
              <v-col cols="4">
                <h5 style="padding: 10px; text-align: center">
                  القسط :
                  {{
                    findSectionInstallmentForYear(level[0].studentLevel, level[0].studyYearId).amount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "IQD",
                    })
                  }}
                </h5>
              </v-col>
              <v-col cols="4">
                <h5 style="padding: 10px; text-align: left" v-if="level[0].studyYearId > 72">
                  المتبقي :
                  {{
                    getStudentLevelDiscountAmount(level[0].studentLevel, level[0].studyYearId) != undefined
                      ? (
                          findSectionInstallmentForYear(level[0].studentLevel, level[0].studyYearId).amount * ((100 - findChannelForLevel(level[0].studentLevel).discount) / 100) -
                          totalInstallmentPayed(level) -
                          getStudentLevelDiscountAmount(level[0].studentLevel, level[0].studyYearId).amount -
                          studentCircularPrevious(level)
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "IQD",
                        })
                      : (
                          findSectionInstallmentForYear(level[0].studentLevel, level[0].studyYearId).amount * ((100 - findChannelForLevel(level[0].studentLevel).discount) / 100) -
                          totalInstallmentPayed(level) -
                          studentCircularPrevious(level)
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "IQD",
                        })
                  }}
                </h5>
              </v-col>
            </v-row>

            <v-row style="padding: 10px">
              <v-col cols="11">الملاحظات : {{ studentLevelNotes(level[0].studyYearId, level[0].collegeNumber) }}</v-col>

              <v-col cols="1" v-if="studentLevelNotes(level[0].studyYearId, level[0].collegeNumber) == 'لايوجد'" style="text-align: left">
                <!-- <v-btn color="primary" fab x-small dark> <v-icon>mdi-plus</v-icon></v-btn> -->
                <AddLevelNotes v-if="$store.state.userData.idEmployee != 614" :collegeNumber="level[0].collegeNumber" :yearStudyId="level[0].studyYearId" @reloadTask="initialData()" />
              </v-col>

              <v-col cols="1" v-else style="text-align: left">
                <EditLevelNotes :levelNote="studentLevelNotesId(level[0].studyYearId, level[0].collegeNumber)" @reloadTask="initialData()" v-if="$store.state.userData.idEmployee != 614" />
                <!-- <v-btn color="primary" fab x-small dark> <v-icon>mdi-pencil</v-icon></v-btn> -->
              </v-col>
            </v-row>
          </v-card>
        </v-card>
      </v-container>
    </div>
  </div>
</template>

<script>
import AddStudentPay from "../components/AddStudentPay.vue";
import SoftDeleteStudentVoucher from "../components/SoftDeleteStudentVoucher.vue";
import EditAcceptedType from "../components/EditAcceptedType.vue";
import AddLevelNotes from "../components/AddLevelNotes.vue";
import EditLevelNotes from "../components/EditLevelNotes.vue";
import AddStudentDiscountAmount from "../components/AddStudentDiscountAmount.vue";
import EditDiscountAmount from "../components/EditDiscountAmount.vue";
import AddStudentProfilePhoto from "../components/AddStudentProfilePhoto.vue";
import ShowStudentProfilePhotos from "../components/ShowStudentProfilePhotos.vue";
import ShowDiscountAmountNotes from "../components/ShowDiscountAmountNotes.vue";
import EditSectionIdVoucher from "../components/EditSectionIdVoucher.vue";
import n2wordsAR from "n2words/lib/i18n/AR.mjs";
var JsBarcode = require("jsbarcode");

export default {
  components: {
    AddStudentPay,
    EditAcceptedType,
    SoftDeleteStudentVoucher,
    AddLevelNotes,
    EditLevelNotes,
    EditSectionIdVoucher,
    AddStudentDiscountAmount,
    EditDiscountAmount,
    ShowDiscountAmountNotes,
    AddStudentProfilePhoto,
    ShowStudentProfilePhotos,
  },
  data: () => ({
    haveDiscount: false,
    currentStudentPay: 0,
    amountPay: 0,
    sectionCost: 0,
    overlay: false,
    studentPayDetails: "",
    discountTypeId: "",
    discountStudent: "",
    paymentTypes: [],
    sectionInstallmentStudent: 0,
    allStudentFees: [],
    acceptedTypes: [],
    paymentTypesAll: [],
    allStudentPayments: [],
    levelNotes: [],
    studentDiscountAmount: [],
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "رقم الوصل", value: "voucherNumber" },
      { text: "تسديد عن", value: "typeName" },
      { text: "للمرحلة", value: "studentLevel" },
      { text: "تاريخ الوصل", value: "paymentDateFormat" },
      { text: "المبلغ", value: "amount" },
      { text: "قسم الوصل", value: "sectionVoucher" },
      {
        text: " الملاحظات",
        value: "notes",
      },
      { text: "الاجراءات", value: "actions" },
    ],
    studentDiscountPercentage: "",
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    studentPaymentsForAll: [],
    profilePhotos: [],
    studentChannelLevels: [],
    allSectionInstallments: [],
  }),
  created() {
    console.log(this.$store.state.studentDetails);
    this.initialData();
  },
  methods: {
    deleteDiscountAmount(data){
      console.log(data);
      let self = this;
      self.$http.delete(`${self.$store.state.apiUrlFees}/studentDiscountAmount/${data.idStudentDiscountAmount}`,{
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }).then((response) => {
          console.log(response);
          self.initialData();
        }).catch((error) => {
          console.log(error);
        });

    },
    isStudentTransfer() {
      console.log("----------------------------------------------------------------");
      console.log(this.$store.state.studentDetails);
      console.log("================================================================");

      if (this.$store.state.studentDetails.yearStudyId == 75 || this.$store.state.studentDetails.studentStatusId == 3) {
        return true;
      } else {
        return false;
      }
    },
    initialData() {
      let self = this;
      console.log(self.$store.state.user);
      self.overlay = true;
      if (self.$store.state.studentDetails?.level) {
        self.$http
          .all([
            self.$http.get(`${self.$store.state.apiUrlFees}/studentPaymentsDetailsInstallment/${self.$store.state.studentDetails.collegeNumber}`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }),

            self.$http.get(
              `${self.$store.state.apiUrlFees}/filterSectionInstallments?sectionId=${self.$store.state.studentDetails.sectionId}&studyYearId=75&level=${self.$store.state.studentDetails.level}&studyType=${self.$store.state.studentDetails.studyType}`,
              {
                headers: { Authorization: `Bearer ${self.$store.state.user}` },
              }
            ),
            self.$http.get(`${self.$store.state.apiUrlFees}/paymentTypesInstallment`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }),

            self.$http.get(`${self.$store.state.apiUrlRegistration}/acceptedTypes`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }),
            self.$http.get(`${self.$store.state.apiUrlFees}/${this.$store.state.studentDetails.studentStatusId == 3 ? "paymentTypesForNotContinue" : "paymentTypesForContinue"}`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }),
            self.$http.get(`${self.$store.state.apiUrlRegistration}/levelNoteByStudentLevelId?collegeNumber=${self.$store.state.studentDetails.collegeNumber}`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }),

            self.$http.get(
              `${self.$store.state.apiUrlFees}/levelStudentDiscountAmount?collegeNumber=${self.$store.state.studentDetails.collegeNumber}&studentLevel=${self.$store.state.studentDetails.level}`,
              {
                headers: { Authorization: `Bearer ${self.$store.state.user}` },
              }
            ),

            self.$http.get(`${self.$store.state.apiUrlFees}/studentProfilePhotos/${self.$store.state.studentDetails.collegeNumber}`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }),

            self.$http.get(`${self.$store.state.apiUrlRegistration}/studentAcceptedChannelByStudentId/${self.$store.state.studentDetails.idStudent}`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }),

            self.$http.get(`${self.$store.state.apiUrlFees}/sectionInstallmentsBySectionId/${self.$store.state.studentDetails.sectionId}`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }),
          ])
          .then(
            self.$http.spread((studentPayments, sectionInstallment, paymentTypes, acceptedTypes, allPaymentsType, notes, discountAmount, profilePhotos, studentChannel, allSectionInstallment) => {
              self.studentDiscountAmount = discountAmount.data;

              self.profilePhotos = profilePhotos.data;
              self.studentPaymentsForAll = studentPayments.data.filter((pay) => pay.studyYearId == 75 && pay.paymentTypeId == 33);
              self.levelNotes = notes.data;
              let splitLevel = new Set(
                studentPayments.data.map((student) => {
                  return student.studyYearId;
                })
              );
              self.allStudentPayments = studentPayments.data;
              let filteredLevel = [];

              splitLevel.forEach((year) => {
                let yearData = studentPayments.data.filter((level) => level.studyYearId == year);
                filteredLevel.push(yearData);
              });

              self.allStudentFees = filteredLevel;

              let currentLevelPayment = studentPayments.data.filter((pay) => pay.studentLevel == self.$store.state.studentDetails.level && pay.paymentTypeId < 10 && pay.studyYearId == 75);
              self.currentStudentPay = currentLevelPayment.reduce((n, { amount }) => n + amount, 0);
              self.acceptedTypes = acceptedTypes.data;
              self.sectionCost = sectionInstallment.data[0].amount * ((100 - self.$store.state.studentDetails.discount) / 100);
              self.sectionInstallmentStudent = sectionInstallment.data[0].amount;
              self.discountStudent = sectionInstallment.data[0].discount;
              self.$store.state.paymentTypesInstallment = paymentTypes.data;
              self.paymentTypesAll = allPaymentsType.data;
              console.log(this.$store.state.studentDetails);

              JsBarcode("#barcode", `${self.$store.state.studentDetails.collegeNumber}`, {
                width: 3,
                height: 30,
                displayValue: false,
              });

              console.log(sectionInstallment.data);
              self.studentChannelLevels = studentChannel.data;
              self.allSectionInstallments = allSectionInstallment.data;
              self.overlay = false;
            })
          )
          .catch((error) => {
            self.overlay = false;
            console.log(error);
          });
      } else {
        let self = this;
        self.overlay = false;
        self.$http
          .all([
            self.$http.get(`${self.$store.state.apiUrlFees}/studentPaymentsDetailsInstallment/${self.$store.state.studentDetails.collegeNumber}`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }),
            self.$http.get(`${self.$store.state.apiUrlFees}/paymentTypesInstallment`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }),
            self.$http.get(`${self.$store.state.apiUrlRegistration}/acceptedTypes`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }),
            self.$http.get(`${self.$store.state.apiUrlFees}/${this.$store.state.studentDetails.studentStatusId == 3 ? "paymentTypesForNotContinue" : "paymentTypesForContinue"}`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }),
            self.$http.get(`${self.$store.state.apiUrlRegistration}/studentAcceptedChannelByStudentId/${self.$store.state.studentDetails.idStudent}`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }),

            self.$http.get(`${self.$store.state.apiUrlFees}/sectionInstallmentsBySectionId/${self.$store.state.studentDetails.sectionId}`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }),
          ])
          .then(
            self.$http.spread((studentPayments, paymentTypes, acceptedTypes, allPaymentsType, studentChannel, allSectionInstallment) => {
              self.studentPaymentsForAll = studentPayments.data.filter((pay) => pay.studyYearId == 75 && pay.paymentTypeId == 33);
              let splitLevel = new Set(
                studentPayments.data.map((student) => {
                  return student.studyYearId;
                })
              );
              console.log(splitLevel);
              let filteredLevel = [];
              splitLevel.forEach((year) => {
                let yearData = studentPayments.data.filter((level) => level.studyYearId == year);
                filteredLevel.push(yearData);
              });
              self.allStudentFees = filteredLevel;
              let currentLevelPayment = studentPayments.data.filter((pay) => pay.studentLevel == self.$store.state.studentDetails.level && pay.paymentTypeId < 10 && pay.studyYearId == 75);
              self.currentStudentPay = currentLevelPayment.reduce((n, { amount }) => n + amount, 0);
              self.acceptedTypes = acceptedTypes.data;
              self.$store.state.paymentTypesInstallment = paymentTypes.data;
              self.paymentTypesAll = allPaymentsType.data;
              self.studentChannelLevels = studentChannel.data;
              self.allSectionInstallments = allSectionInstallment.data;
              self.overlay = false;
            })
          )
          .catch((error) => {
            self.overlay = false;
            console.log(error);
          });
      }
    },
    findChannelForLevel(level) {
      let self = this;
      console.log(level);
      let found = self.studentChannelLevels.filter((channel) => channel.studentLevel == level);

      if (found.length > 0) {
        return found[0];
      } else {
        return {
          acceptedName: "لايوجد",
          discount: 0,
        };
      }
    },

    findSectionInstallmentForYear(level, year) {
      let self = this;
      console.log("================================================================================================");
      // console.log(`${year}  ---------------------------------------------------------------- ${level}`);
      console.log(self.allSectionInstallments);
      console.log("================================================================================================");
      let found = self.allSectionInstallments.filter(
        (installment) => installment.studyYearId == year && installment.level == level && installment.studyType == self.$store.state.studentDetails.studyType
      );

      if (found.length > 0) {
        return found[0];
      } else {
        return {
          amount: 0,
        };
      }
    },
    saveData() {
      let self = this;
      if (self.studentDiscountPercentage && self.discountTypeId) {
        let data = {
          studentId: self.$store.state.studentDetails.idStudent,
          studentName: self.$store.state.studentDetails.studentName,
          level: self.$store.state.studentDetails.studentLevel.level,
          sectionName: self.$store.state.studentDetails.sectionName,
          studyType: self.$store.state.studentDetails.studyType,
          acceptedTypeId: self.$store.state.studentDetails.acceptedType.idAcceptedType,
          acceptedTypeName: self.$store.state.studentDetails.acceptedType.acceptedName,
          discount: self.studentDiscountPercentage,
          discountTypeId: self.discountTypeId,
          createdBy: self.$store.state.userData.idUser,
        };
        self.$http.post(`${self.$store.state.apiUrlFees}/addStudentDiscount`, data).then((response) => {
          console.log(response);
          self.initialData();
        });
      } else {
        this.$toasted.error("الرجاء اختيار نوع التخفيض ونسبته ");
      }
    },
    getTheStudentPreviousData(level, yearId, payments) {
      let self = this;
      if (self.getStudentLevelDiscountAmount(level, yearId) != undefined) {
        return (
          self.findSectionInstallmentForYear(level, yearId).amount * ((100 - self.findChannelForLevel(level).discount) / 100) -
          self.totalInstallmentPayed(payments) -
          self.getStudentLevelDiscountAmount(level, yearId).amount -
          self.studentCircularPrevious(payments)
        );
      } else {
        return (
          self.findSectionInstallmentForYear(level, yearId).amount * ((100 - self.findChannelForLevel(level).discount) / 100) -
          self.totalInstallmentPayed(payments) -
          self.studentCircularPrevious(payments)
        );
      }
    },
    getStudentLevelDiscountAmount(studentLevel, yearStudyId) {
      let self = this;

      let foundStudent = self.studentDiscountAmount.find((student) => student.studentLevel == studentLevel && student.yearStudyId == yearStudyId);

      return foundStudent;
    },

    getStudentCircularAmount() {
      let self = this;
      if (self.studentPaymentsForAll.length > 0) {
        return self.studentPaymentsForAll[0].amount;
      } else {
        return 0;
      }
    },

    studentLevelNotes(yearStudyId) {
      let self = this;
      if (self.levelNotes.length > 0) {
        let filteredData = self.levelNotes.find((note) => note.yearStudyId == yearStudyId);
        if (filteredData) {
          return filteredData.notes;
        } else {
          return "لايوجد";
        }
      } else {
        return "لايوجد";
      }
    },

    studentLevelNotesId(yearStudyId) {
      let self = this;
      if (self.levelNotes.length > 0) {
        let filteredData = self.levelNotes.find((note) => note.yearStudyId == yearStudyId);
        if (filteredData) {
          return filteredData;
        } else {
          return "لايوجد";
        }
      } else {
        return "لايوجد";
      }
    },

    getPercentage(paymentTypeId, arrayOfPayments, sectionCost) {
      if (paymentTypeId == 9) {
        return "100%";
      } else {
        let percentage = this.totalInstallmentPayed(arrayOfPayments) - sectionCost;
        return percentage;
      }
    },

    studentCircularPrevious(payments) {
      let circular = payments.filter((payment) => payment.paymentTypeId == 33);
      if (circular.length > 0) {
        return circular.reduce((n, { amount }) => n + amount, 0);
      } else {
        return 0;
      }
    },

    totalInstallmentPayed(arrayOfPayments) {
      if (arrayOfPayments.length > 0) {
        let filteredPayment = arrayOfPayments.filter((pay) => pay.paymentTypeId < 10);
        return filteredPayment.reduce((n, { amount }) => n + amount, 0);
      } else {
        return 0;
      }
    },

    printVoucher(voucher, arrayOfPayments) {
      let self = this;
      let stylesHtml = "";
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }

      let image = require("../assets/logo.png");
      let bg = require("../assets/bg.png");

      // Open the print window
      const WinPrint = window.open("", "", "left=0,top=0,width=1000,height=900,toolbar=0,scrollbars=0,status=0");
      let numberWriting = n2wordsAR(voucher.amount, { lang: "ar" });
      let barcodeCanvas = document.getElementById("barcode");
      let currentDate = `${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`;
      // let newStyle = stylesHtml.replaceAll("portrait", "landscape");
      // let lastStyle = newStyle.replaceAll("A4", "A5");

      //later
      //${this.$store.state.studentDetails.discount > 0? `<h5 data-v-4a284cfa="">بناءاً على قرار مجلس الكلية تقرر تخفيض نسبة ${this.$store.state.studentDetails.discount}% من القسط الاجمالي</h5>`: ""}

      console.log(this.$store.state.studentDetails);
      WinPrint.document.write(`<!DOCTYPE html>
      <!DOCTYPE html>
        <html lang="en">
        ${stylesHtml}

          <style>
          body ,html{ margin:0px !important;}
          *{font-family: sans-serif !important;}
          body{
            border:1px solid #eee !important;
          }
          .vl {
                border-right: 1px dashed #000;
          }
          @media print {
              @page {
                size: A5 landscape !important;
                margin: 0cm;
              }
              @page :left {
                margin: 0cm;
              }
              @page :right {
                margin: 0cm;
              }
              .not-printable {
                display: none !important;
              }
              body {
                -webkit-print-color-adjust: exact !important;
                height: 100% !important;
                width: 100% !important;
                padding: 0px !important;
                margin: 0px !important;
                color: #000;
                background-color: #fff !important;
                background-image: url("${bg}") !important;
                page-break-before: avoid;
                page-break-before: avoid;
              }
              .adjust-main {
                padding: 0px !important;
              }
              header nav,
              footer {
                display: none;
              }
              h3 {
                font-size: 11px !important;
              }
              h5 {
                font-size: 9px !important;
              }

              
              
              .center-print {
                background: #ffffff !important;
              }

              .image-print {
                width: 100px !important;
                height: 100px !important;
                text-align: center !important;
                margin: auto !important;
              }
              span {
                font-size: 10px !important;
                font-weight: bolder !important;
              }
              .center-print {
                text-align: center !important;
              }
            }
            img {
              padding: 5px;
            }
            .image-print {
              width: 80px !important;
              height: 80px !important;
              text-align: center !important;
              margin: auto !important;
            }
            span {
              font-size: 10px !important;
              font-weight: bolder !important;
            }

            .center-print {
              text-align: center !important;
              background: #ffffff !important;
              background-image: url("${bg}") !important;
              background-repeat: no-repeat !important;
              background-size: 100% !important;
            }
            .print-padding {
              padding: 5px !important;
              margin: 0 20px;
            }
            .col{
            padding: 5px !important;
            }
          </style>
          <body >
            <div data-v-4a284cfa="" class="center-print" style="border: 2px solid rgb(37, 37, 37); margin-top: 0px;height:100vh">
              <div data-v-4a284cfa="">
                <div data-v-4a284cfa="">
                 <div style="height:145px"></div>
                  
                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-4">
                        <div class="row" style="border:1px dashed #000;padding:0px;border-radius:6px">
                        <div class="col col-5">
                          <h4 data-v-4a284cfa="" >${this.$store.state.studentDetails.studentStatusId == 4 ? voucher.paymentDateFormat : voucher.paymentDateFormat}</h4>  
                        </div>
                        <div class="col col-1 vl">
                        </div>
                         <div class="col col-6">
                          <h4>تاريخ التسديد</h4>
                        </div>
                     
                      </div>
                    
                    </div>
                    <div data-v-4a284cfa="" class="col col-4">
                    <h4 data-v-4a284cfa="" > لسنة :  ${voucher.year}</h4>
                    </div>
                    <div data-v-4a284cfa="" class="col col-4">
                      
                      <div class="row" style="border:1px dashed #000;padding:0px;border-radius:6px">
                        <div class="col col-5">
                          <h4>${self.sectionCost.toLocaleString("en-US", {
                            style: "currency",
                            currency: "IQD",
                          })}</h4> 
                        </div>
                        <div class="col col-1 vl">
                        </div>
                         <div class="col col-6">
                          <h4>اجور الدراسة</h4>
                        </div>
                     
                      </div>
                    </div>
                  
                  </div>


                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-4">
                        <div class="row" style="border:1px dashed #000;padding:0px;border-radius:6px">
                        <div class="col col-5">
                          <h4 data-v-4a284cfa="" style="margin-right:20px">${voucher.voucherNumber}</h4>  
                        </div>
                        <div class="col col-1 vl"></div>
                         <div class="col col-6">
                          <h4>رقم سند القبض</h4>
                        </div>
                     
                      </div>
                    
                    </div>
                    <div data-v-4a284cfa="" class="col col-4"></div>
                    <div data-v-4a284cfa="" class="col col-4">
                      
                      <div class="row" style="border:1px dashed #000;padding:0px;border-radius:6px">
                        <div class="col col-5">
                          <h4>%${self.getPaidPercentage(voucher, arrayOfPayments)}</h4> 
                        </div>
                        <div class="col col-1 vl">
                        </div>
                         <div class="col col-6">
                          <h4>نسبة التحصيل</h4>
                        </div>
                     
                      </div>
                    </div>
                  
                  </div>

                  <div style="height:35px"></div>


                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-6">
                      <div style="margin:0 10px 0 0px">
                        <div class="row" style="border:1px dashed #000;padding:0px;border-radius:6px">
                        <div class="col col-6">
                          <h4 data-v-4a284cfa="" style="margin-right:20px">${voucher.collegeNumber}</h4>  
                        </div>
                        <div class="col col-1 vl"></div>
                         <div class="col col-5">
                          <h4>الرقم الجامعي</h4>
                        </div>
                      </div>
                      </div>
                    </div>
                    
                    <div data-v-4a284cfa="" class="col col-6">
                      <div style="margin:0 0 0 10px">
                      <div class="row" style="border:1px dashed #000;padding:0px;border-radius:6px">
                        <div class="col col-8">
                          <h4>${self.$store.state.studentDetails.studentName}</h4>
                        </div>
                        <div class="col col-1 vl">
                        </div>
                         <div class="col col-3">
                          <h4>الاسم</h4>
                        </div>
                      </div>
                      </div>
                   </div>
                  </div>

                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-2">
                        <div class="row" style="border:1px dashed #000;padding:0px;border-radius:6px">
                        <div class="col col-6">
                          <h4 data-v-4a284cfa="" >${self.$store.state.studentDetails.studyType == 1 ? "صباحي" : "مسائي"}</h4>  
                        </div>
                        <div class="col col-1 vl">
                        </div>
                         <div class="col col-5">
                          <h4>الدراسة</h4>
                        </div>
                     
                      </div>
                    
                    </div>
                    <div data-v-4a284cfa="" class="col col-6">
                    <div style="margin:0 20px 0 20px">
                    <div class="row" style="border:1px dashed #000;padding:0px;border-radius:6px">
                        <div class="col col-8">
                          <h4>${self.$store.state.studentDetails.sectionName}</h4> 
                        </div>
                        <div class="col col-1 vl">
                        </div>
                         <div class="col col-3">
                          <h4>القسم</h4>
                        </div>
                     
                      </div>
                      </div>
                    </div>

                    <div data-v-4a284cfa="" class="col col-4">
                      <div class="row" style="border:1px dashed #000;padding:0px;border-radius:6px">
                        <div class="col col-8">
                          <h4>${self.getCollege(self.$store.state.studentDetails)}</h4> 
                        </div>
                        <div class="col col-1 vl">
                        </div>
                         <div class="col col-3">
                          <h4>الكلية</h4>
                        </div>
                     
                      </div>
                    </div>
                  </div>


                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-12">
                      <div style="margin:0 0px 0 0px">
                        <div class="row" style="border:1px dashed #000;padding:0px;border-radius:6px">
                        <div class="col col-8">
                          <h4 data-v-4a284cfa="" style="margin-right:20px"> ${numberWriting} فقط لاغير 
                            ${voucher.amount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "IQD",
                            })} </h4>  
                        </div>
                        <div class="col col-1 vl"></div>
                         <div class="col col-3">
                          <h4>المبلغ المدفوع رقماً وكتابة</h4>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>


                   <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-6">
                      <div style="margin:0 20px 0 0px">
                        <div class="row" style="border:1px dashed #000;padding:0px;border-radius:6px">
                        <div class="col col-6">
                          <h4 data-v-4a284cfa="" style="margin-right:20px">${self.$options.filters.setLevel(self.$store.state.studentDetails.level)}</h4>  
                        </div>
                        <div class="col col-1 vl"></div>
                         <div class="col col-5">
                          <h4>المرحلة</h4>
                        </div>
                      </div>
                      </div>
                    </div>
                    
                    <div data-v-4a284cfa="" class="col col-6">
                      <div style="margin:0 0 0 10px">
                      <div class="row" style="border:1px dashed #000;padding:0px;border-radius:6px">
                        <div class="col col-8">
                          <h4> ${voucher.paymentTypeId != 36 ? `${voucher.typeName}` : ``} </h4>
                        </div>
                        <div class="col col-1 vl">
                        </div>
                         <div class="col col-3">
                          <h4>وذلك عن</h4>
                        </div>
                      </div>
                      </div>
                   </div>
                  </div>


                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-12">
                      <div style="margin:0 0px 0 0px">
                        <div class="row" style="border:1px dashed #000;padding:0px;border-radius:6px">
                        <div class="col col-8">
                          <h4 data-v-4a284cfa="" style="margin-right:20px"> ${voucher.notes}</h4>  
                        </div>
                        <div class="col col-1 vl"></div>
                         <div class="col col-3">
                          <h4>الملاحظات</h4>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>

                      <div style="height:20px"></div>

                      <div style="height:30px"></div>
                      <h4 style="text-align: right;margin-right: 30px;">
                       الموظف المختص : ${voucher.employeeName}
                      </h4>
                </div>
              </div>
            </div>
          </body>

        </html>
        `);

      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(() => {
        WinPrint.print();
        WinPrint.close();
      }, 1500);
    },
    getCollege(student) {
      switch (student.sectionName) {
        case "تقنية صناعة الاسنان":
        case "تقنيات التخدير":
        case "تقنيات المختبرات الطبية":
        case "تقنيات الاشعة":
        case "تقنيات البصريات":
          return "كلية التقنيات الصحية والطبية";
        case "التربية البدنية وعلوم الرياضة":
          return "كلية التربية البدنية";
        case "مالية ومصرفية":
        case "المحاسبة":
        case "ادارة اعمال":
        case "علوم السياحة و الفندقة":
          return "كلية الادارة والاقتصاد";
        case "هندسة تقنيات الحاسوب":
        case "تقنيات التبريد والتكيف":
        case "تقنيات اجهزة طبية":
        case "هندسة القدرة الكهربائية":
          return "الكلية التقنية الهندسية";
        case "الصيدلة":
          return "كلية الصيدلة";
        case "القانون":
          return "كلية القانون";
        case "اللغة الانكليزية":
          return "كلية الاداب";
        case "علوم الحاسوب":
        case "الادلة الجنائية":
        case "علوم الامن السيبراني":
        case "علوم الحياة":
        case "الذكاء الاصطناعي":
          return "كلية العلوم";
        case "الدراسات الاسلامية":
        case "العلوم النفسية والتربوية":
        case "العلوم التربوية والنفسية":
          return "كلية التربية";
        case "الطب الحياتي":
        case "هندسة تكرير النفط والغاز":
        case "تقنيات الطيران":
          return "كلية الهندسة";
        case "الاعلام":
          return "كلية الاعلام";
        case "التصميم":
          return "كلية الفنون";
        case "طب الاسنان":
          return "كلية طب الاسنان";
        case "التمريض":
          return "كلية التمريض";

        default:
          return " ";
      }
    },
    isPaidIdentity() {
      let self = this;
      let paid = self.allStudentPayments.find((pay) => pay.paymentTypeId == 24 && pay.studyYearId == 75);
      return paid ? true : false;
    },

    isPaidService() {
      let self = this;
      let paid = self.allStudentPayments.find((pay) => pay.paymentTypeId == 36 && pay.studyYearId == 75);
      return paid ? true : false;
    },
    getPaidPercentage(selected, allStudentPayment) {
      let self = this;

      let paid = 0;

      console.log(selected);

      if (selected.paymentTypeId == 1) {
        let allPaid = allStudentPayment.filter((pay) => pay.paymentTypeId == 1);
        paid =
          ((allPaid.reduce((n, { amount }) => n + amount, 0) * 1 +
            self.getStudentCircularAmount() +
            (self.getStudentLevelDiscountAmount(self.$store.state.studentDetails.level, self.$store.state.studentDetails.yearStudyId)?.amount
              ? self.getStudentLevelDiscountAmount(self.$store.state.studentDetails.level, self.$store.state.studentDetails.yearStudyId)?.amount
              : 0)) /
            self.sectionCost) *
          1 *
          100;

        return Math.floor(paid);
      }

      if (selected.paymentTypeId == 3) {
        let allPaid = allStudentPayment.filter((pay) => pay.paymentTypeId == 1 || pay.paymentTypeId == 2 || pay.paymentTypeId == 3);
        paid =
          ((allPaid.reduce((n, { amount }) => n + amount, 0) * 1 +
            self.getStudentCircularAmount() +
            (self.getStudentLevelDiscountAmount(self.$store.state.studentDetails.level, self.$store.state.studentDetails.yearStudyId)?.amount
              ? self.getStudentLevelDiscountAmount(self.$store.state.studentDetails.level, self.$store.state.studentDetails.yearStudyId)?.amount
              : 0)) /
            self.sectionCost) *
          1 *
          100;

        return Math.floor(paid);
      }

      if (selected.paymentTypeId == 4) {
        let allPaid = allStudentPayment.filter((pay) => pay.paymentTypeId == 1 || pay.paymentTypeId == 2 || pay.paymentTypeId == 3 || pay.paymentTypeId == 4);
        paid =
          ((allPaid.reduce((n, { amount }) => n + amount, 0) * 1 +
            self.getStudentCircularAmount() +
            (self.getStudentLevelDiscountAmount(self.$store.state.studentDetails.level, self.$store.state.studentDetails.yearStudyId)?.amount
              ? self.getStudentLevelDiscountAmount(self.$store.state.studentDetails.level, self.$store.state.studentDetails.yearStudyId)?.amount
              : 0)) /
            self.sectionCost) *
          1 *
          100;
        return Math.floor(paid);
      }

      if (selected.paymentTypeId == 5) {
        let allPaid = allStudentPayment.filter((pay) => pay.paymentTypeId == 1 || pay.paymentTypeId == 2 || pay.paymentTypeId == 3 || pay.paymentTypeId == 4 || pay.paymentTypeId == 5);
        paid =
          ((allPaid.reduce((n, { amount }) => n + amount, 0) * 1 +
            self.getStudentCircularAmount() +
            (self.getStudentLevelDiscountAmount(self.$store.state.studentDetails.level, self.$store.state.studentDetails.yearStudyId)?.amount
              ? self.getStudentLevelDiscountAmount(self.$store.state.studentDetails.level, self.$store.state.studentDetails.yearStudyId)?.amount
              : 0)) /
            self.sectionCost) *
          1 *
          100;

        return Math.floor(paid);
      }

      if (selected.paymentTypeId == 6) {
        let allPaid = allStudentPayment.filter(
          (pay) => pay.paymentTypeId == 1 || pay.paymentTypeId == 2 || pay.paymentTypeId == 3 || pay.paymentTypeId == 4 || pay.paymentTypeId == 5 || pay.paymentTypeId == 6
        );
        paid =
          ((allPaid.reduce((n, { amount }) => n + amount, 0) * 1 +
            self.getStudentCircularAmount() +
            (self.getStudentLevelDiscountAmount(self.$store.state.studentDetails.level, self.$store.state.studentDetails.yearStudyId)?.amount
              ? self.getStudentLevelDiscountAmount(self.$store.state.studentDetails.level, self.$store.state.studentDetails.yearStudyId)?.amount
              : 0)) /
            self.sectionCost) *
          1 *
          100;

        return Math.floor(paid);
      }

      if (selected.paymentTypeId == 7) {
        let allPaid = allStudentPayment.filter(
          (pay) => pay.paymentTypeId == 1 || pay.paymentTypeId == 2 || pay.paymentTypeId == 3 || pay.paymentTypeId == 4 || pay.paymentTypeId == 5 || pay.paymentTypeId == 6 || pay.paymentTypeId == 7
        );
        paid =
          ((allPaid.reduce((n, { amount }) => n + amount, 0) * 1 +
            self.getStudentCircularAmount() +
            (self.getStudentLevelDiscountAmount(self.$store.state.studentDetails.level, self.$store.state.studentDetails.yearStudyId)?.amount
              ? self.getStudentLevelDiscountAmount(self.$store.state.studentDetails.level, self.$store.state.studentDetails.yearStudyId)?.amount
              : 0)) /
            self.sectionCost) *
          1 *
          100;

        return Math.floor(paid);
      }

      if (selected.paymentTypeId == 8) {
        let allPaid = allStudentPayment.filter(
          (pay) =>
            pay.paymentTypeId == 1 ||
            pay.paymentTypeId == 2 ||
            pay.paymentTypeId == 3 ||
            pay.paymentTypeId == 4 ||
            pay.paymentTypeId == 5 ||
            pay.paymentTypeId == 6 ||
            pay.paymentTypeId == 7 ||
            pay.paymentTypeId == 8
        );
        paid =
          ((allPaid.reduce((n, { amount }) => n + amount, 0) * 1 +
            self.getStudentCircularAmount() +
            (self.getStudentLevelDiscountAmount(self.$store.state.studentDetails.level, self.$store.state.studentDetails.yearStudyId)?.amount
              ? self.getStudentLevelDiscountAmount(self.$store.state.studentDetails.level, self.$store.state.studentDetails.yearStudyId)?.amount
              : 0)) /
            self.sectionCost) *
          1 *
          100;

        return Math.floor(paid);
      }

      if (selected.paymentTypeId == 9) {
        return 100;
      }

      let allPaid = allStudentPayment.filter(
        (pay) =>
          pay.paymentTypeId == 1 ||
          pay.paymentTypeId == 2 ||
          pay.paymentTypeId == 3 ||
          pay.paymentTypeId == 4 ||
          pay.paymentTypeId == 5 ||
          pay.paymentTypeId == 6 ||
          pay.paymentTypeId == 7 ||
          pay.paymentTypeId == 8 ||
          pay.paymentTypeId == 9
      );
      paid =
        ((allPaid.reduce((n, { amount }) => n + amount, 0) * 1 +
          self.getStudentCircularAmount() +
          (self.getStudentLevelDiscountAmount(self.$store.state.studentDetails.level, self.$store.state.studentDetails.yearStudyId)?.amount
            ? self.getStudentLevelDiscountAmount(self.$store.state.studentDetails.level, self.$store.state.studentDetails.yearStudyId)?.amount
            : 0)) /
          self.sectionCost) *
        1 *
        100;

      return Math.floor(paid);
    },
  },

  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      } else if (level == 5) {
        return "المرحلة الخامسة";
      } else {
        return "متخرج";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
