<template>
  <div>
    <v-container class="container not-printable" fluid>
      <v-card elevation="0">
        <v-row style="margin: 10px">
          <v-col cols="3">
            <h4>اسم الطالب : {{ $store.state.selectedStudent.studentName }}</h4>
          </v-col>
          <v-col cols="3">
            <h4>رقم الهاتف : {{ $store.state.selectedStudent.phone }}</h4>
          </v-col>
          <v-col cols="3">
            <h4>التحصيل الدراسي : {{ $store.state.selectedStudent.certificate }}</h4>
          </v-col>
          <v-col cols="3">
            <h4>العنوان : {{ $store.state.selectedStudent.address }}</h4>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="container not-printable" fluid>
      <v-card elevation="0">
        <v-row>
          <v-col cols="8">
            <v-card elevation="2">
              <v-card-text>
                <v-row>
                  <v-col cols="8">
                    <h4>مدفوعات الطالب</h4>
                  </v-col>
                  <v-col cols="4" align="left"><AddStudentPayForCourse :allCourses="courses" @reloadTask="getData" /> </v-col>
                </v-row>
              </v-card-text>
              <v-data-table :headers="headersPay" :items="$store.state.selectedStudent.payments" :items-per-page="15" item-key="idStudent" class="table-content">
                <template v-slot:[`item.index`]="{ index }">
                  <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn icon @click="printVoucher(item)">
                    <v-icon color="primary"> mdi-printer </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card elevation="2">
              <v-card-text>
                <v-row>
                  <v-col cols="8">
                    <h4>كورسات الطالب</h4>
                  </v-col>
                  <v-col cols="4" align="left">
                    <AddCourseForStudent :allCourses="courses" @reloadTask="getData" :studentId="$store.state.selectedStudent.idStudentInformation" />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-data-table :headers="headers" :items="$store.state.selectedStudent.courses" :items-per-page="15" item-key="idStudent" class="table-content">
                <template v-slot:[`item.index`]="{ index }">
                  <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import AddStudentPayForCourse from "./AddStudentPayForCourse.vue";
import AddCourseForStudent from "./AddCourseForStudent.vue";
import n2wordsAR from "n2words/lib/i18n/AR.mjs";
export default {
  components: {
    AddStudentPayForCourse,
    AddCourseForStudent,
  },
  props: {
    courses: Array,
    student: Object,
  },
  data: () => ({
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "اسم الكورس", value: "courseName" },
      { text: "السعر", value: "coursePrice" },
    ],
    headersPay: [
      { text: "التسلسل", value: "index" },
      { text: "اسم الكورس", value: "courseName" },
      { text: "السعر", value: "price" },
      { text: "التاريخ", value: "dateFormat" },
      { text: "بواسطة", value: "employeeName" },
      { text: "الاجراءات", value: "actions" },
    ],
  }),
  methods: {
    getData() {
      let self = this;
      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrlFees}/studentIdCourses/${self.$store.state.selectedStudent.idStudentInformation}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlFees}/studentCoursePay/${self.$store.state.selectedStudent.idStudentInformation}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((course, pays) => {
            self.ShowDetails = true;
            self.$store.state.selectedStudent = { ...self.student, courses: course.data, payments: pays.data };
          })
        )
        .catch((e) => console.log(e));
    },
    printVoucher(item) {
      let self = this;
      let stylesHtml = "";
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }

      let image = require("../../../assets/logo.png");
      let bg = require("../../../assets/bg.png");

      // Open the print window
      const WinPrint = window.open("", "", "left=0,top=0,width=1000,height=900,toolbar=0,scrollbars=0,status=0");
      let numberWriting = n2wordsAR(item.price, { lang: "ar" });
      // let newStyle = stylesHtml.replaceAll("portrait", "landscape");
      // let lastStyle = newStyle.replaceAll("A4", "A5");

      //later
      //${this.$store.state.studentDetails.discount > 0? `<h5 data-v-4a284cfa="">بناءاً على قرار مجلس الكلية تقرر تخفيض نسبة ${this.$store.state.studentDetails.discount}% من القسط الاجمالي</h5>`: ""}

      WinPrint.document.write(`<!DOCTYPE html>
      <!DOCTYPE html>
        <html lang="en">
        ${stylesHtml}

          <style>
          body ,html{ margin:0px !important;}
          *{font-family: sans-serif !important;}
          body{
            border:1px solid #eee !important;
          }
          .vl {
                border-right: 1px dashed #000;
          }
          @media print {
              @page {
                size: A5 landscape !important;
                margin: 0cm;
              }
              @page :left {
                margin: 0cm;
              }
              @page :right {
                margin: 0cm;
              }
              .not-printable {
                display: none !important;
              }
              body {
                -webkit-print-color-adjust: exact !important;
                height: 100% !important;
                width: 100% !important;
                padding: 0px !important;
                margin: 0px !important;
                color: #000;
                background-color: #fff !important;
                background-image: url("${bg}") !important;
                page-break-before: avoid;
                page-break-before: avoid;
              }
              .adjust-main {
                padding: 0px !important;
              }
              header nav,
              footer {
                display: none;
              }
              h3 {
                font-size: 11px !important;
              }
              h5 {
                font-size: 9px !important;
              }

              
              
              .center-print {
                background: #ffffff !important;
              }

              .image-print {
                width: 100px !important;
                height: 100px !important;
                text-align: center !important;
                margin: auto !important;
              }
              span {
                font-size: 10px !important;
                font-weight: bolder !important;
              }
              .center-print {
                text-align: center !important;
              }
            }
            img {
              padding: 5px;
            }
            .image-print {
              width: 80px !important;
              height: 80px !important;
              text-align: center !important;
              margin: auto !important;
            }
            span {
              font-size: 10px !important;
              font-weight: bolder !important;
            }

            .center-print {
              text-align: center !important;
              background: #ffffff !important;
              background-image: url("${bg}") !important;
              background-repeat: no-repeat !important;
              background-size: 100% !important;
            }
            .print-padding {
              padding: 5px !important;
              margin: 0 20px;
            }
            .col{
            padding: 5px !important;
            }
          </style>
          <body >
            <div data-v-4a284cfa="" class="center-print" style="border: 2px solid rgb(37, 37, 37); margin-top: 0px;height:100vh">
              <div data-v-4a284cfa="">
                <div data-v-4a284cfa="">
                 <div style="height:145px"></div>
                  
                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-4">
                        <div class="row" style="border:1px dashed #000;padding:0px;border-radius:6px">
                        <div class="col col-5">
                          <h4 data-v-4a284cfa="" >${item.dateFormat}</h4>  
                        </div>
                        <div class="col col-1 vl">
                        </div>
                         <div class="col col-6">
                          <h4>تاريخ التسديد</h4>
                        </div>
                     
                      </div>
                    
                    </div>
                    <div data-v-4a284cfa="" class="col col-4">
                    
                    </div>
                    <div data-v-4a284cfa="" class="col col-4">
                      
                      <div class="row" style="border:1px dashed #000;padding:0px;border-radius:6px">
                        <div class="col col-5">
                          <h4>${item.coursePrice.toLocaleString("en-US", {
                            style: "currency",
                            currency: "IQD",
                          })}</h4> 
                        </div>
                        <div class="col col-1 vl">
                        </div>
                         <div class="col col-6">
                          <h4>الاجور</h4>
                        </div>
                     
                      </div>
                    </div>
                  
                  </div>


                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-4">
                        <div class="row" style="border:1px dashed #000;padding:0px;border-radius:6px">
                        <div class="col col-5">
                          <h4 data-v-4a284cfa="" style="margin-right:20px">${item.idCoursePay}</h4>  
                        </div>
                        <div class="col col-1 vl"></div>
                         <div class="col col-6">
                          <h4>رقم سند القبض</h4>
                        </div>
                     
                      </div>
                    
                    </div>
                    <div data-v-4a284cfa="" class="col col-4"></div>
                    <div data-v-4a284cfa="" class="col col-4">
                      
                    
                    </div>
                  
                  </div>

                  <div style="height:35px"></div>


                  <div data-v-4a284cfa="" class="row print-padding">
                  
                    
                    <div data-v-4a284cfa="" class="col col-6">
                      <div style="margin:0 0 0 10px">
                      <div class="row" style="border:1px dashed #000;padding:0px;border-radius:6px">
                        <div class="col col-8">
                          <h4>${self.$store.state.selectedStudent.studentName}</h4>
                        </div>
                        <div class="col col-1 vl">
                        </div>
                         <div class="col col-3">
                          <h4>الاسم</h4>
                        </div>
                      </div>
                      </div>
                   </div>
                  </div>

                 


                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-12">
                      <div style="margin:0 0px 0 0px">
                        <div class="row" style="border:1px dashed #000;padding:0px;border-radius:6px">
                        <div class="col col-8">
                          <h4 data-v-4a284cfa="" style="margin-right:20px"> ${numberWriting} فقط لاغير 
                            ${item.price.toLocaleString("en-US", {
                              style: "currency",
                              currency: "IQD",
                            })} </h4>  
                        </div>
                        <div class="col col-1 vl"></div>
                         <div class="col col-3">
                          <h4>المبلغ المدفوع رقماً وكتابة</h4>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>


                 



                      <div style="height:20px"></div>

                      <div style="height:30px"></div>
                      <h4 style="text-align: right;margin-right: 30px;">
                       الموظف المختص : ${item.employeeName}
                      </h4>
                </div>
              </div>
            </div>
          </body>

        </html>
        `);

      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(() => {
        WinPrint.print();
        WinPrint.close();
      }, 1500);
    },
  },
};
</script>

<style lang="scss" scoped></style>
