<template>
  <div>
    <!-- <v-card> -->
    <v-tabs v-model="tab" background-color="deep-black accent-4" centered dark icons-and-text>
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-1">
        الطلبة
        <v-icon>mdi-monitor-account</v-icon>
      </v-tab>

      <v-tab href="#tab-2">
        الكورسات
        <v-icon>mdi-monitor-shimmer</v-icon>
      </v-tab>

      <v-tab href="#tab-3">
        القبض اليومي
        <v-icon>mdi-account-credit-card</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <v-card flat>
          <Students />
        </v-card>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <v-card flat>
          <Courses />
        </v-card>
      </v-tab-item>
      <v-tab-item value="tab-3">
        <v-card flat>
          <PayDaily />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <!-- </v-card> -->
  </div>
</template>

<script>
import Students from "@/components/course/Students.vue";
import Courses from "@/components/course/Courses.vue";
import PayDaily from "@/components/course/PayDaily.vue";
export default {
  components: {
    Students,
    Courses,
    PayDaily,
  },
  data() {
    return {
      tab: null,
    };
  },
  created() {
    this.initialization();
  },
  methods: {
    initialization() {
      let self = this;
      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrlFees}/paymentTypesForContinue`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((paymentTypes) => {
            self.paymentTypes = paymentTypes.data.filter((pay) => pay.idPaymentType < 10);
          })
        )
        .catch((e) => console.log(e));
    },
  },
};
</script>

<style lang="scss" scoped></style>
